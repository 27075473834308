<template>
    <v-app style="background-color: #f2f2f2">
      <v-layout row wrap align-center justify-center>
        <v-flex lg4 md5 sm7 xs7 class="text-center">
          <img src="@/assets/logo_onebox-horizontal-png.png" width="45%" height="45%" alt="OneBox" />
          <br />
          <br />
          <v-progress-linear
            v-if="fail_ === false"
            background-color="light-blue lighten-4"
            color="success"
            indeterminate
            rounded
          ></v-progress-linear>
          <br />
          <p v-if="fail_ === false" class="text-center">รอสักครู่</p>
          <p v-else class="text-center"><v-icon color="error">error</v-icon>&nbsp;{{errormessage}}</p>
        </v-flex>
      </v-layout>
    </v-app>
  </template>
  <script>
  import Swal from "sweetalert2/dist/sweetalert2.js";
  import "sweetalert2/src/sweetalert2.scss";
  import { mapState, mapGetters } from "vuex";
  import gbfGenerate from "@/globalFunctions/generateAuthorize";
  import VueCookies from "vue-cookies";
  
  const Toast = Swal.mixin({
    toast: true,
    position: "top-end",
    showConfirmButton: false,
    timer: 3000,
  });
  
  export default {
    data: function() {
      return {
        fail_: false,
        errormessage: "",
      };
    },
    created() {},
    computed: {
      ...mapState(["username", "authorize", "account_active", "color", "role_level"]),
      ...mapState({ processloader: "loading" }),
      ...mapGetters([
        "dataUsername",
        "dataAuthorize",
        "dataAccountActive",
        "dataAccesstoken",
        "dataBusinessProfile",
        "dataCitizenProfile",
        "dataDepartmentAccessId",
        "dataAccountId",
        "dataLoginDefault",
      ]),
    },
    methods: {     
      gotonextpage() {
            console.log("เข้า");
            let phone = this.$route.query.mobile
            let URL 
            console.log("====",process.env.NODE_ENV);
            // if (process.env.VUE_APP_ENVIRONMENT === "production") {
            //     // URL = "https://one.th/api/oauth/getcode?client_id=26&response_type=code&redirect_url=box.one.th&mobile_no=" + phone
            //     URL = "https://testoneid.inet.co.th/api/oauth/getcode?client_id=155&response_type=code&redirect_url=https://uatbox.one.th&mobile_no=" + phone
            //     console.log("URL",URL);
            //     window.open(URL, "_self");
            //     // window.open(URL, "_blank");
            // }else{
                URL = "https://one.th/api/oauth/getcode?client_id=26&response_type=code&redirect_uri=https://onebox.siameastsolutions.com/login&mobile_no=" + phone
                // URL = "https://testoneid.inet.co.th/api/oauth/getcode?client_id=155&response_type=code&redirect_uri=https://uatbox.one.th/login&mobile_no=" + phone
                console.log("URL",URL);
                window.open(URL, "_self");
                // window.open(URL, "_blank");
            }
        // }
    },
    mounted() {
      console.log(",kkkkkkkkk", this.$route.query);
      this.gotonextpage();
    }
  }
  </script>
  